/**
 * Purpose : contains all api endpoints used in the project
 * Created Date : 24-April-2020
 * Created By : Tajinder Singh
 */

export const EndPoints ={
    
    GenerateTempToken                   :  'User/GenerateTempToken',
    OTPVerification                     :  'User/RequestForOTP',
    SignUp                              :  'User/SignUp',
    Login                               :  'User/Login',
    ForgotPassword                      :  'User/ForgotPassword',
    SubmitGeneralPHR                    :  'PHR/SubmitGeneralPHR',
    SubmitHealthRecord                  :  'PHR/SubmitHealthRecord',
    SubmitInsuranceDetails              :  'PHR/SubmitInsuranceDetails',
    GetPHRList                          :  'PHR/GetPHRList',
    GetPHRInsuranceDetails              :  'PHR/GetPHRInsuranceDetails',
    GetInsuranceDetailById              :  'PHR/GetInsuranceDetailById',
    GetGeneralPHRDetail                 :  'PHR/GetGeneralPHRDetail',
    GetPHRHealthRecords                 :  'PHR/GetPHRHealthRecords',
    GetHealthRecordDetails              :  'PHR/GetHealthRecordDetails',
    GetCountries                        :  'Common/GetCountries',
    GetBloodGroupList                   :  'PHR/GetBloodGroupList',
    GetMasterData                       :  'DoctorProfile/GetMasterData',
    GetDoctorProfile                    :  'DoctorProfile/GetDoctorProfile',
    EditPersonalProfile                 :  'DoctorProfile/EditPersonalProfile',
    EditProfessionalProfile             :  'DoctorProfile/EditProfessionalProfile',
    DetailsOfPatient                    :  'PatientProfile/PatientProfileInfo',
    ChangePassword                      :  'User/ChangePassword',
    SearchForDoctor                     :  'SearchDoctor/SearchForDoctor',
    GetSearchedDoctorProfile            :  'SearchDoctor/GetSearchedDoctorProfile',
    GetDailyAppointmentScheduleById     :  'AppointmentBooking/GetDailyAppointmentScheduleById',
    ConfirmAppointment                  :  'AppointmentBooking/ConfirmAppointment',
    CancelAppointment                   :  'UserHome/CancelAppointment',
    GetPatientAppointmentHistory        :  'UserHome/GetPatientAppointmentHistory',
    GetPatientUpcomingAppointment       :  'UserHome/GetPatientUpcomingAppointment',
    GetAllMembership                    :  'User/GetAllMemberShip',
    GetUserMembership                   :  'User/GetUserMemberShip',
    PurchaseMemberShip                  :  'User/PurchaseMemberShip',
    GetPharmacyList                     :  'Pharmacy/GetPharmacyList',
    GetProducts                         :  'Pharmacy/GetProducts',
    GetPrescription                     :  'Pharmacy/GetPrescription',
    GetAllAddresses	                    :  'PatientProfile/GetAllAddresses',
    GetPendingOrders                    :  'Pharmacy/GetPendingOrders',
    GetPrescriptionReportDetails        :  'PHR/GetPrescriptionReportDetails',
    AddNewAddress                       :  'PatientProfile/AddNewAddress',
    SendPrescription                    :  'Pharmacy/SendPrescription',
    GetOrderHistory                     :  'Pharmacy/GetOrderHistory',
    PlaceOrder                          :  'Pharmacy/PlaceOrder',
    GetOrderedProductDetail             :  'Pharmacy/GetOrderedProductDetail',
    CancelOrConfirmOrder                :  'Pharmacy/CancelOrConfirmOrder',
    GetPrescribedDrugsDetail            :  'Pharmacy/GetPrescribedDrugsDetail',
    PharmacyReviewAndRating             :  'Pharmacy/PharmacyReviewAndRating',
    GetPharmacyReview                   :  'Pharmacy/GetPharmacyReview',
    EditProfile                         :  'PatientProfile/EditProfile',
    ChangeDefaultAddress                :  'PatientProfile/ChangeDefaultAddress',
    UpdateExistingAddress               :  'PatientProfile/UpdateExistingAddress',
    AddCard                             :  'Payment/AddCard',
    GetCards                            :  'Payment/GetCards',
    PaymentTransaction                  :  'Payment/PaymentTransaction',
    DeleteAddress                       :  'PatientProfile/DeleteAddress',
    Logout                              :  'User/Logout',
    DeleteCard                          :  'Payment/DeleteCard',
    ConfirmOrder                        :  'Pharmacy/ConfirmOrder',
    CancelOrder                         :  'Pharmacy/CancelOrder',
    GetConferenceRoom                   :  'CallingServer/GetConferenceRoom',
    SendConferenceMail                  :  'CallingServer/SendConferenceMail',
    PublishPatient                      :  'CallingServer/PublishPatient',
    GetPharmacyConferenceRoom           :  'CallingServer/GetPharmacyConferenceRoom',
    ChangeNotificationSetting           :  'Setting/ChangeNotificationSetting',
    GetPushList	                        :  'Setting/GetPushList',
    GetSettings                         :  'Setting/GetSettings',
    GetVisitReasonList                  :  'PHR/GetVisitReasonList',
    GetMedicationList                   :  'PHR/GetMedicationList',
    GetInsuranceDropdownLists           :  'PHR/GetInsuranceDropdownLists',
    SubmitPVR                           :  'PHR/SubmitPVR',
    GetSIL                              :  'Common/GetSIL',
    CallToFrontDesk                     :  'CallingServer/CallToFrontDesk',
    NotificationAcknowledgement         :  'CallingServer/NotificationAcknowledgement',
    GetNotificationById                 :  'CallingServer/GetNotificationById',
    GetUserWallet                       :  'User/GetUserWallet',
    GetWalletTransactions               :  'User/GetWalletTransactions',
    WalletTransaction                   :  'User/WalletTransaction',
    SearchUser                          :  'User/SearchUser',
    RequestBalance                      :  'User/RequestBalance',
    GetTransferBalanceNotifications     :  'User/GetTransferBalanceNotifications',
    FAQ                                 :  'https://api.etelmedconnect.com/home/FAQ',
    ContactUs                           :  'https://api.etelmedconnect.com/home/ContactUs',
    TermsAndConditions                  :  'https://api.etelmedconnect.com/home/TermsAndConditions',
    PrivacyPolicy                       :  'https://api.etelmedconnect.com/home/PrivacyPolicy',
    VideoURL                            :  'https://patient.etelmedconnect.com/',//'https://staging.etelmedconnect.com/',
    UserManual                          :  'https://etelmed.s3-us-west-2.amazonaws.com/PATIENT/PatientApp_User_manual_eTelmed.pdf',
    GetPVRDetails                       :  'PHR/GetPVRDetailById',
    
}