import { NgModule } from '@angular/core';
import {MatInputModule} from '@angular/material/input';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatRadioModule} from '@angular/material/radio';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material';
import {MatSelectModule} from '@angular/material/select';

import {MatCheckboxModule} from '@angular/material/checkbox';

const MaterialComponent =[
  MatInputModule,
  MatToolbarModule,
  MatNativeDateModule,
  MatSidenavModule,
  MatSelectModule,
  MatRadioModule,
  MatDatepickerModule,
  MatListModule,
  MatIconModule,
  MatTableModule,
  MatButtonModule,
  MatCheckboxModule,
  MatExpansionModule
  
]

@NgModule({

  imports: [
    MaterialComponent ],
  exports: [MaterialComponent]
})
export class MaterialModule { }
