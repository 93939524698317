import { Component, OnInit } from '@angular/core';
import { NgbModalModule, NgbModalOptions, NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-closemodalbtn',
  templateUrl: './closemodalbtn.component.html',
  styleUrls: ['./closemodalbtn.component.scss']
})
export class ClosemodalbtnComponent implements OnInit {

  constructor(
    public modalservice: NgbModal,
  ) { }

  ngOnInit() {
  }

  closeDialogue(){
    this.modalservice.dismissAll();
  }

}
