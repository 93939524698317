/**
 * Purpose : contains all urls used in the project
 * Created Date : 24-April-2020
 * Created By : Tajinder Singh
 */

export const SiteURL = {
    APIBaseURL : 'https://api.etelmedconnect.com/api/',
    //APIBaseURL : 'http://localhost:24393/api/',
    S3URL :'https://etelmed.s3.amazonaws.com/',
    bucketname : 'etelmed'
}

