import { Routes } from "@angular/router";
import { LandingComponent } from '../landing/landing.component';
import { PrivacyPolicyComponent } from '../privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from '../terms-conditions/terms-conditions.component';
import { FaqComponent } from '../faq/faq.component';
import { TelehealthComponent } from '../telehealth/telehealth.component';


export const PUBLIC_ROUTES: Routes = [

    {
        path: '',
        component: LandingComponent
    },
    {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent
    },
    {
        path: 'faq',
        component: FaqComponent
    },
    {
        path: 'terms-and-condition',
        component: TermsConditionsComponent
    },
    {
        path: 'membership',
       component: TelehealthComponent
    }

];
