import * as CryptoJS from 'crypto-js';

export const EncryptionDecryption = {

    encrypt(data:any, cryptoKey:any):any {
        
        var key = CryptoJS.enc.Utf8.parse(cryptoKey);
        var iv = CryptoJS.enc.Utf8.parse(cryptoKey);
   
        var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(JSON.stringify(data)), key,{
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        return encrypted.toString();
    },
    decrypt(data:any, cryptoKey:any):any {
        var key = CryptoJS.enc.Utf8.parse(cryptoKey);
        var iv = CryptoJS.enc.Utf8.parse(cryptoKey);
        
        var decrypted = CryptoJS.AES.decrypt(data, key, {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        return decrypted.toString(CryptoJS.enc.Utf8);
    }
}