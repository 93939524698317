import { Component, OnInit } from '@angular/core';
import { NgwWowService } from 'ngx-wow';
import { StorageService  } from '../../helper/storage.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {

  constructor ( private StorageService:StorageService, private wowService: NgwWowService) { }
  islogdIn = this.StorageService.getDataFromStorage('token')?true:false;
  ngOnInit() {
    this.wowService.init();
  }

}
