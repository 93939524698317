import { Component, OnInit, ViewChild } from '@angular/core';
import { StorageService  } from '../../helper/storage.service';
import {EndPoints} from '../../constant/APIRoutes';
import { Router } from '@angular/router';
import {MainService} from '../../helper/main.service';
import { NotifierService } from "angular-notifier";
import { MatSidenav } from '@angular/material';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild('sidenav',{static: true}) sidenav: MatSidenav;

  islogdIn : boolean = this.StorageService.getDataFromStorage('token') ? true : false;
  
  
  EndPoints = EndPoints
  private readonly notifier: NotifierService;
  
  constructor(
    private StorageService:StorageService,
    private router: Router,
    private mainService : MainService,
    notifierService: NotifierService,
  ) { 
    this.notifier = notifierService;
  }
  
  ngOnInit() {
   console.log("log", this.islogdIn); 
  }

  logOut(){
    this.mainService.get(EndPoints.Logout, this.StorageService.getDataFromStorage('cryptoKey'), true).pipe().subscribe(response => {
      if(response.IsSuccess){
        this.notifier.notify("Success", response.Message);
        localStorage.clear();
        this.router.navigate(['/'])   
      }
      else{
        this.notifier.notify("Error", response.Message);
      }
    });

  }
  closesideNav(reason: string) {
    this.sidenav.close();
  }

}
