import { Routes } from "@angular/router";
import { GuardService } from "../../auth/guard/guard.service";

export const SECURE_ROUTES: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("../../../app/modules/settings/settings.module").then(
        (m) => m.SettingsModule
      ),
  },

  {
    path: "home",
    canLoad: [GuardService],
    loadChildren: () =>
      import("../../../app/modules/home/home.module").then((m) => m.HomeModule),
  },
  {
    path: "phr",
    canLoad: [GuardService],
    loadChildren: () =>
      import("../../../app/modules/phr/phr.module").then((m) => m.PhrModule),
  },
  {
    path: "search-doctor",
    canLoad: [GuardService],
    loadChildren: () =>
      import("../../../app/modules/search-doctor/search-doctor.module").then(
        (m) => m.SearchDoctorModule
      ),
  },
  {
    path: "health",
    canLoad: [GuardService],
    loadChildren: () =>
      import("../../../app/modules/health/health.module").then(
        (m) => m.HealthModule
      ),
  },
  {
    path: "insurance",
    canLoad: [GuardService],
    loadChildren: () =>
      import("../../../app/modules/insurance/insurance.module").then(
        (m) => m.InsuranceModule
      ),
  },
  {
    path: "my-wallet",
    canLoad:[GuardService],
    loadChildren: () =>
      import("../../../app/modules/wallet/wallet.module").then(
        (m) => m.WalletModule
      ),
  },
  {
    path: "",
    // canLoad:[GuardService],
    loadChildren: () =>
      import("../../../app/modules/videoconference/videoconference.module").then(
        (m) => m.VideoconferenceModule
      ),
  },

  {
    path: "",
    // canLoad:[GuardService],
    loadChildren: () =>
      import("../../../app/search-pharmacy/search-pharmacy.module").then(
        (m) => m.SearchPharmacyModule
      ),
  },
];
