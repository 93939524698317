import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route,CanLoad,CanActivateChild } from '@angular/router';
import {StorageService} from '../../helper/storage.service'

@Injectable({
  providedIn: 'root'
})
export class GuardService {

  constructor(
    private router: Router,
    private StorageService:StorageService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
    let url = state.url;
   return this.checkLogin(url);
 }

 canLoad(route: Route): boolean {
   let url =route.path;
   // console.log("canLoad  "+url)
   return this.checkLogin(url);
 }

 canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
  
   return this.canActivate(route, state);
 }


 
 checkLogin(url: string){
 
   if(this.StorageService.getDataFromStorage('token') && url ==='/login'){
    this.router.navigate(['/'])
     return false;
   }
   if(this.StorageService.getDataFromStorage('token')){
     return true;
   }
   // console.log(" AuthGuard attemptedUrl ="+url)
   this.router.navigate(['/login'],{queryParams: {attemptedUrl:url}});
   return false;
 }

}
