import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedataService {
  private _items = {};
  constructor() { }

  addItem(key,item) {
    this._items[key]=item;
  }

  getItems(){
      return this._items;
  }
}
