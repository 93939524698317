import { Component } from '@angular/core';
import { NgxUiLoaderService } from "ngx-ui-loader";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    private loader:NgxUiLoaderService
  ){
    //this.loader.start()
  }
  title = 'doctors-app';
}
