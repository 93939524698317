import { Component, OnInit } from '@angular/core';
import {MainService} from '../../helper/main.service';
import {EndPoints} from '../../constant/APIRoutes';
import { StorageService  } from  '../../helper/storage.service';
import { NotifierService } from "angular-notifier";

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit {

  private readonly notifier: NotifierService;

  constructor(
    private mainService : MainService,
    private StorageService:StorageService,
    notifierService: NotifierService,
  ) { 
    this.notifier = notifierService;
  }

  ngOnInit() {
    this.mainService.getHTML(EndPoints.TermsAndConditions).subscribe(data => {
      console.log(data);
    },
    error => console.log('Error from backend API', +error));
  }

}
