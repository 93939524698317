import { Injectable } from '@angular/core';
import {HttpEvent, HttpErrorResponse,HttpInterceptor,HttpHandler,HttpRequest,HttpHeaders, HttpResponse, HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map,catchError, tap } from "rxjs/operators";
import {SiteURL} from "../constant/SiteUrl";
import {EncryptionDecryption} from "./criptoHelper"
import { StorageService  } from  './storage.service';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { NotifierService } from "angular-notifier";

@Injectable({
  providedIn: 'root'
})
export class MainService  {

  private readonly notifier: NotifierService;
   
  constructor(
    private http:HttpClient,private router: Router,
    private StorageService:StorageService,
    private loader:NgxUiLoaderService,
    notifierService: NotifierService,
    ) { 
      this.notifier = notifierService;
    }

  invalidToken(res){
    console.log("================>>>>>>>>>>")
    if(res.Status==400 && res.Message=='Invalid Token'){
      this.StorageService.removeDataFromStorage('token');
      localStorage.clear();
      this.router.navigate(['/'])   ;
      this.notifier.notify("Success", "You have logged in some other device.");
      return false
    }
    return res
  }

  api_url = (`${SiteURL.APIBaseURL}`)

  get httpHeaders(){

    let token = localStorage.getItem('token') != '' ? localStorage.getItem('token') : '';
    if(token){
      return {  headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Token': token.replace('"',''),
      })}
    }
    else{
      return {
        headers:new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        })
      }
    }
  }

  post(payload: any, url: string, isEnc:boolean, cryptoKey: any ): Observable<any> {
    this.loader.start();
    let body = new URLSearchParams();

    if(isEnc){
      console.log(payload);
      payload = EncryptionDecryption.encrypt(payload, cryptoKey);
      body.set('request', payload);
      payload = body.toString();
      //console.log(payload);
    }
    else{
      payload = payload.toString();
    }
        
    return this.http.post<any>(this.api_url + url, payload, this.httpHeaders).pipe(
      catchError(err => {
        console.log("Error ==> ",err)
        this.loader.stop();
        return this.invalidToken(err.error)
        
      }),
      map((resp)=>{
        this.loader.stop();
        //console.log("response ==> ",resp)
         if(isEnc){
           resp.Result = EncryptionDecryption.decrypt(resp.Result, cryptoKey);
         }
         return this.invalidToken(resp)
      }),//resp => resp
    )
  }

  put(payload: any, url: string, isEnc:boolean, cryptoKey: any): Observable<any> {
    if(isEnc){
      payload = EncryptionDecryption.encrypt(payload, cryptoKey);
    }
    return this.http.put<any>(this.api_url + url, payload,this.httpHeaders).pipe(
      catchError(err => {
        console.log(err)
        return this.invalidToken(err.error)
      }),
      map((resp)=>{
        if(isEnc){
          resp = EncryptionDecryption.decrypt(resp, cryptoKey);
        }
        return this.invalidToken(resp)
      }),
    )
  }

  get(url: any, cryptoKey: any, isEnc:boolean): Observable<any> {
    this.loader.start();
    return this.http.get<any>(this.api_url + url, this.httpHeaders).pipe(
      catchError(err => {
        console.log("================>>>>>>>>>>")
        console.log(err)
        this.loader.stop();
        return this.invalidToken(err.error)
      }),
      map((resp)=>{
        //console.log("response ==> ",resp)
          if(isEnc)
            resp.Result = EncryptionDecryption.decrypt(resp.Result, cryptoKey);
            this.loader.stop();
          return this.invalidToken(resp)
      }),//resp => resp
    )  
  }

  getHTML(url: string): Observable<string> {
    return this.http.get<string>(url, this.httpHeaders);
 }

}
