import { Component, OnInit } from '@angular/core';
import { StorageService  } from '../../helper/storage.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  card=[
    {
      heading: 'Time Saving',
      description: 'Log into your account and get consultancy while saving travelling time.',
    },
    {
      heading: 'Avilability',
      description: 'Log into your account and get consultancy while saving travelling time.',
    },
    {
      heading: 'All type of Doctor',
      description: 'Get in touch with any type of doctor that you want to consult with as per your condition.',
    },
    {
      heading: 'All type of Doctor',
      description: 'Get in touch with any type of doctor that you want to consult with as per your condition.',
    }
  ]
  constructor(private StorageService:StorageService) { }
  islogdIn = this.StorageService.getDataFromStorage('token')?true:false;

  ngOnInit() {
  }

}
