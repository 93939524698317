import { Component, OnInit } from '@angular/core';
import { StorageService  } from  '../../helper/storage.service';
import {MainService} from '../../helper/main.service';
import {EndPoints} from '../../constant/APIRoutes';
import { NotifierService } from "angular-notifier";
import { Router } from '@angular/router';
import { userHome } from '../../models/UserHome/user';
import {SharedataService} from '../../helper/sharedata.service'

@Component({
  selector: 'app-telehealth',
  templateUrl: './telehealth.component.html',
  styleUrls: ['./telehealth.component.scss']
})
export class TelehealthComponent implements OnInit {
  private readonly notifier: NotifierService;
  user = new userHome();
  membershipCard=[];
  tempMembershipCard = [];
  userMemberShipCard = [];
  isMember: boolean = false;

  constructor(
    private StorageService:StorageService,
    private mainService : MainService,
    private router: Router,
    notifierService: NotifierService,
    private SharedataService:SharedataService
  ) { 
      this.notifier = notifierService;
  }

  ngOnInit(): void {

    //Get User Membership..
    this.mainService.get(EndPoints.GetUserMembership, this.StorageService.getDataFromStorage('cryptoKey'), true).pipe().subscribe(response => {
      if(response.IsSuccess){
        console.log(response);
        var Result = JSON.parse(response.Result);
        this.userMemberShipCard = Result.UserMemberShipList;
        if(this.userMemberShipCard.length > 0)
          this.isMember = true;
        this.notifier.notify("Success", response.Message);
      }
      else{
        this.notifier.notify("Error", response.Message);
      }
    });

    //Get All Memberships..
    this.mainService.post(this.user.GetAllMemberShips(), EndPoints.GetAllMembership, true, this.StorageService.getDataFromStorage('cryptoKey')).pipe().subscribe(response => {
      if(response.IsSuccess){
        console.log(response);
        var Result = JSON.parse(response.Result);
        this.membershipCard = Result.MemberShipList;
        this.tempMembershipCard = Result.MemberShipList;
        this.notifier.notify("Success", response.Message);
      }
      else{
        this.notifier.notify("Error", response.Message);
      }
    });

  }

  fAllMemberShips(){
    this.membershipCard = this.tempMembershipCard;
  }

  fQuarterlyMemberShips(){
    this.membershipCard = this.tempMembershipCard.filter(x => x.MemberShipTennure === 3);
  }
  
  fHalfYearlyMemberShips(){
    this.membershipCard = this.tempMembershipCard.filter(x => x.MemberShipTennure == 6);
  }
  
  fYearlyMemberShips(){
    this.membershipCard = this.tempMembershipCard.filter(x => x.MemberShipTennure == 12);
  }

  buyNow(value, memberShip){
    this.SharedataService.addItem("packagePrice", value)
    this.SharedataService.addItem("isMemberShip", true);
    this.SharedataService.addItem("memberShip", memberShip);
    this.router.navigate(['/my-wallet/payment-method']);
  }
}
