import { Component, OnInit } from '@angular/core';
import {  NavigationEnd, Router } from '@angular/router';
import { NgwWowService } from 'ngx-wow';
import { StorageService } from 'src/app/helper/storage.service';
@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {

  constructor ( private StorageService  :StorageService, private wowService: NgwWowService , private router: Router) { }
    islogdIn = this.StorageService.getDataFromStorage('token')?true:false;

    currenRoute: any
  ngOnInit(): void {
    this.wowService.init();
    console.log(this.router.url); 
    
    this.currenRoute = this.router.url.split('/')[1];
    console.log(this.currenRoute, "jhkjhkj"); 

   
  }

}
