import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { PublicComponentComponent } from './public-component/public-component.component';
import { SecureComponentComponent } from './secure-component/secure-component.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { BannerComponent } from './banner/banner.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { FaqComponent } from './faq/faq.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { LandingComponent } from './landing/landing.component';
import { MaterialModule } from '../material/material-module';
import { TelehealthComponent } from './telehealth/telehealth.component';
import { ConfirmationPopupComponent } from './confirmation-popup/confirmation-popup.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import { ClosemodalbtnComponent } from './closemodalbtn/closemodalbtn.component';
import { InputComponent } from '../auth/input/input.component';
import { HealthlistComponent } from '../auth/healthlist/healthlist.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CarouselComponent } from './carousel/carousel.component';
import { HealthcareComponent } from './healthcare/healthcare.component';
import { CardCarouselComponent } from './card-carousel/card-carousel.component';


@NgModule({
  declarations: [PublicComponentComponent, SecureComponentComponent, HeaderComponent, FooterComponent, BannerComponent, PrivacyPolicyComponent, 
    InputComponent,
    HealthlistComponent, TermsConditionsComponent, FaqComponent, AboutUsComponent, LandingComponent, TelehealthComponent, ConfirmationPopupComponent, ClosemodalbtnComponent,
  CarouselComponent,HealthcareComponent,CardCarouselComponent
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    MaterialModule,
    MatDialogModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule
    
  ],
  exports: [
    ConfirmationPopupComponent,
    ClosemodalbtnComponent
  
  ],
  entryComponents: [
    ConfirmationPopupComponent,

  ]
  


})
export class SharedModule { }
