import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PublicComponentComponent } from './shared/public-component/public-component.component';
import{PUBLIC_ROUTES} from '../app/shared/public-component/public-routes'
import { SecureComponentComponent } from './shared/secure-component/secure-component.component';
import{SECURE_ROUTES} from '../app/shared/secure-component/secure-routes';


const routes: Routes = [
  // {
  //   path: 'new',
  //   loadChildren: () =>
  //     import("../app/modules/home-module-new/home-module-new.module").then(
  //       (m) => m.HomeModuleNewModule
  //     ),
  // },


  {
    path: '',
    component: PublicComponentComponent,
    children: PUBLIC_ROUTES
  },

  {
    path: '',
    component: SecureComponentComponent,
    children: SECURE_ROUTES
  },
  {
    path: '',
    loadChildren: () =>
      import("../app/auth/auth.module").then(
        (m) => m.AuthModule
      ),
  },
  

  // {
  //   path: '**',
  //   redirectTo: '',
  // },
 



];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: "enabled", 
    useHash: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
