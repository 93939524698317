import { ParseError } from '@angular/compiler';
import { parse } from 'querystring';
import { JsonPipe } from '@angular/common';
import { FormsModule, FormControlName } from '@angular/forms';

/**
 * Class Name : userPHR
 * Purpose : user model class used to create user request models for login, signup etc..
 * Created Date : 24-04-2020
 * Created By : Tajinder Singh
 */
export class userHome {
    
    constructor(){

    }

    //#region [ Global Variable Declaration]
    
    
    UserMemberShipId : number;
    PrescriptionId : number;
    IsPublic : boolean;
    WalletID : number;
    CardNumber : string;
    Name : string;
    ExpirationMonth : string;
    ExpirationYear : string;
    CVV : string;
    CardId: string;

    UserID : number;
    UserName : string;
    Description : string;
    DebitAmount : number;
    CreditAmount : number;
    TransactionType : number;
    PaymentMode : number;
    Amount : string;
    BeforeDiscountAmount : string;
    DiscountAmount : string;
    DiscountDescription : string;

    MemberShipId : number;
    MemberShipName : string;
    FreeCalls : number;
    PharmacyDiscountPercentage : number;
    LabDiscountPercentage : number;
    MemberShipTennure : number;

    SearchKey : string;
    NotificationID : number;
    TransferToWalletID : number;
    TransferToUserID : number;
    TransferToUserName : string;

    RequestFromUserID : number;
    RequestFromWalletID : number;
    RequestFromName : string;
    RequestFromUserImageUrl : string;
    RequestFromUserWalletID : string;
    RequestToUserID : number;
    RequestToWalletID : number;
    RequestedAmount : number;
    
    //#endregion
    

    /**
     * Function Name : PatientAppointmentModel
     * Parameters : Take formControls object
     * Returns : User Upcoming Appointment JSON Object
     * Purpose : To return user Upcoming Appointment request
     * Created Date : 07-May-2020
     * Created By : Tajinder Singh  
     */    
    PatientUpcomingAppointmentModel(userMemberShipId: number){

        var obj = { 
            UserMemberShipId: userMemberShipId
        }

        return obj;
    }

    PatientHistoryAppointmentModel(userMemberShipId: number){

        var obj = { 
            UserMemberShipId: userMemberShipId
        }

        return obj;
    }

    GetPrescription(prescriptionId: number){

        var obj = { 
            PrescriptionId: prescriptionId
        }

        return obj;
    }

    GetAllMemberShips(){

        var obj = {
            IsPublic: true
        }

        return obj;
    }

    GetUserWalletTransactions(walletId: number){

        var obj = {
            WalletID: walletId
        }

        return obj;
    }

    AddCard(formControls){

        var obj = {
            CardNumber : formControls.cardNumber,
            Name : formControls.cardHolderName,
            ExpirationMonth : formControls.cardExpiryMonth,
            ExpirationYear : formControls.cardExpiryYear,
            CVV : formControls.cardCVV
        }

        return obj;
    }

    DeleteCard(cardID){
        var obj = {
            CardId: cardID
        }

        return obj;
    }

    MakeTransaction(card, user, amount, UserWallet){
        let temp = JSON.parse(UserWallet);
        var obj = {
            WalletID:  temp[0].WalletID,
            UserID : user.UserId,
            UserName : user.FirstName + ' ' + user.LastName,
            Description : '',
            DebitAmount : 0,
            CreditAmount : amount,
            TransactionType : 1,
            PaymentMode : 1,
            Amount : amount,
            BeforeDiscountAmount : amount,
            DiscountAmount : amount,
            DiscountDescription : '',
            CVV : 123,
            CardId: card.CardId,
        }
        return obj;
    }

    PurchaseMembership(formControls, card){
        var obj = {
            MemberShipId : formControls.MemberShipId,
            MemberShipName : formControls.MemberShipName,
            FreeCalls : formControls.FreeCalls,
            PharmacyDiscountPercentage : formControls.PharmacyDiscountPercentage,
            LabDiscountPercentage : formControls.LabDiscountPercentage,
            PaymentMode : 1,
            MemberShipTennure : formControls.MemberShipTennure,
            Amount : formControls.MemberShipPrice,
            BeforeDiscountAmount : formControls.MemberShipPrice,
            DiscountAmount : formControls.MemberShipPrice,
            DiscountDescription : '',
            CVV : 123,
            CardId: card.CardId,
        }
        return obj;
    }

    SearchUser(formControls){
        var obj = {
            SearchKey : formControls.walletId
        }
        return obj;
    }

    TransferMoney(userDetails, userWallet, tranferToWallet, formControls, notificationId){
        var obj = {
            WalletID : userWallet.WalletID,
            UserID : userDetails.UserId,
            UserName : userDetails.FirstName + ' ' + userDetails.LastName,
            Description : '',
            DebitAmount : formControls.amount,
            CreditAmount : formControls.amount,
            TransactionType : 2, 
            PaymentMode : 1,
            TransferToWalletID : tranferToWallet.WalletID,
            TransferToUserID : tranferToWallet.UserID,
            TransferToUserName : tranferToWallet.FirstName + ' ' + tranferToWallet.LastName,
            NotificationID : notificationId
            
        }
        return obj;
    }

    RequestMoney(userDetails, userWallet, requestToWallet, formControls){
        var obj = {
            RequestFromUserID : userDetails.UserId,
            RequestFromWalletID : userWallet.WalletID,
            RequestFromName : userDetails.FirstName + ' ' + userDetails.LastName,
            RequestFromUserImageUrl : userDetails.UserProfileImage,
            RequestFromUserWalletID : userWallet.UserWalletID,
            RequestToUserID : requestToWallet.UserID,
            RequestToWalletID : requestToWallet.WalletID,
            RequestedAmount : formControls.amount
        }
        return obj;
    }
}