import { Component, OnInit } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class InputComponent implements OnInit {
 
  constructor(
    private parentForm: FormGroupDirective,
    private controlContainer:ControlContainer
  ) { }

  ngOnInit(): void {
    this.parentForm.form.patchValue({
      phoneNumber:"8305827659"
    })
     console.log("======control container",this.parentForm.form)
  }

}
