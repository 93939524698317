import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-public-component',
  templateUrl: './public-component.component.html',
  styleUrls: ['./public-component.component.scss']
})
export class PublicComponentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
